import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography, Link, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, Box, Tabs, Tab,
  Card, CardContent, IconButton, Fab, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import AddToMenuPopup from './AddToMenuPopup';
import MacronutrientsChart from './Charts/MacronutrientsChart';
import { Timer, Restaurant, Edit, Add, StarBorder, Remove } from '@mui/icons-material';

// Componentes estilizados
const RecipeBanner = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '300px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  alignItems: 'flex-end',
  padding: theme.spacing(3),
}));

const RecipeTitle = styled(Typography)(({ theme }) => ({
  position: 'relative',
  zIndex: 1,
  fontWeight: 'bold',
  color: theme.palette.primary.contrastText,
  backgroundColor: 'rgba(0,0,0,0.7)',
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const StyledCardContent = styled(CardContent)({
  flexGrow: 1,
});

const InfoItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1),
  },
}));

function RecetaIndividual() {
  const [receta, setReceta] = useState(null);
  const [ingredientesDetallados, setIngredientesDetallados] = useState([]);
  const [currentServings, setCurrentServings] = useState(0);
  const [originalServings, setOriginalServings] = useState(0);
  const { id } = useParams();
  const [value, setValue] = React.useState(0);
  const { userRole, authToken } = useAuth();
  const navigate = useNavigate();
  const [isAddToMenuOpen, setIsAddToMenuOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/recipes/${id}`)
      .then(response => response.json())
      .then(data => {
        setReceta(data);
        setCurrentServings(data.servings);
        setOriginalServings(data.servings);
        const ingredientesPromise = Promise.all(data.ingredients.map(ing =>
          fetch(`${process.env.REACT_APP_API_URL}/ingredients/${ing.ingredientId}`).then(res => res.json())
        ));
        const tipoDeCocinaPromise = Promise.all(data.tipoDeCocina.map(id =>
          fetch(`${process.env.REACT_APP_API_URL}/tipoDeCocina/${id}`).then(res => res.json())
        ));
        const restriccionesPromise = Promise.all(data.restricciones.map(id =>
          fetch(`${process.env.REACT_APP_API_URL}/restricciones/${id}`).then(res => res.json())
        ));
        const categoriaRecetasPromise = Promise.all(data.categoriaRecetas.map(id =>
          fetch(`${process.env.REACT_APP_API_URL}/categoriaRecetas/${id}`).then(res => res.json())
        ));
        return Promise.all([ingredientesPromise, tipoDeCocinaPromise, restriccionesPromise, categoriaRecetasPromise]);
      })
      .then(([ingredientes, tipoDeCocina, restricciones, categoriaRecetas]) => {
        setIngredientesDetallados(ingredientes);
        setReceta(prevReceta => ({
          ...prevReceta,
          tipoDeCocina: tipoDeCocina.map(item => item.name),
          restricciones: restricciones.map(item => item.name),
          categoriaRecetas: categoriaRecetas.map(item => item.name),
        }));
      })
      .catch(error => console.error('Error:', error));
  }, [id]);

  const calculateAdjustedQuantity = (originalQuantity) => {
    if (!originalServings || originalServings === 0) return originalQuantity;
    return (originalQuantity * currentServings) / originalServings;
  };

  const handleIncreaseServings = () => {
    setCurrentServings(prev => prev + 1);
  };

  const handleDecreaseServings = () => {
    if (currentServings > 1) {
      setCurrentServings(prev => prev - 1);
    }
  };

  const handleAddToMenu = () => {
    setIsAddToMenuOpen(true);
  };

  const handleEditRecipe = () => {
    navigate(`/editar-receta/${id}`);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!receta || ingredientesDetallados.length === 0) return <div>Cargando...</div>;

  return (
    <Box sx={{ maxWidth: 1200, margin: '0 auto', padding: { xs: 2, md: 4 } }}>
      <RecipeBanner style={{ backgroundImage: `url(${receta.mainImage || '/placeholder-image.jpg'})` }}>
        <RecipeTitle variant="h4" style={{ color: 'white' }}>{receta.name}</RecipeTitle>
      </RecipeBanner>

      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12} md={4}>
          <StyledCard>
            <StyledCardContent style={{ textAlign: 'center' }}>
              <Typography variant="h6" gutterBottom>Detalles de la Receta</Typography>
              {receta.prepTime && (
                <InfoItem style={{ justifyContent: 'center', display: 'flex' }}>
                  <img src="https://nutrizia.app/uploads/icono-reloj-100.png" alt="Tiempo" style={{ marginRight: '8px', width: '30px', height: '30px' }} />
                  <Typography>
                    {receta.prepTime.hours || 0}h {receta.prepTime.minutes || 0}m
                  </Typography>
                </InfoItem>
              )}
              <InfoItem style={{ justifyContent: 'center', display: 'flex', alignItems: 'center', gap: 1 }}>
                <img src="https://nutrizia.app/uploads/icono-raciones-100.png" alt="Raciones" style={{ width: '30px', height: '30px' }} />
                <IconButton 
                  size="small" 
                  onClick={handleDecreaseServings}
                  disabled={currentServings <= 1}
                  sx={{ p: 0.5 }}
                >
                  <Remove fontSize="small" />
                </IconButton>
                <Typography>{currentServings} raciones</Typography>
                <IconButton 
                  size="small" 
                  onClick={handleIncreaseServings}
                  sx={{ p: 0.5 }}
                >
                  <Add fontSize="small" />
                </IconButton>
              </InfoItem>
              {receta.difficulty && (
                <InfoItem style={{ justifyContent: 'center', display: 'flex' }}>
                  <StarBorder />
                  <Typography>Dificultad: {receta.difficulty}</Typography>
                </InfoItem>
              )}
              {receta.tipoDeCocina && receta.tipoDeCocina.length > 0 && (
                <Typography><strong>Tipo de Cocina:</strong> {receta.tipoDeCocina.join(', ')}</Typography>
              )}
              {receta.restricciones && receta.restricciones.length > 0 && (
                <Typography><strong>Restricciones:</strong> {receta.restricciones.join(', ')}</Typography>
              )}
              {receta.categoriaRecetas && receta.categoriaRecetas.length > 0 && (
                <Typography><strong>Categoría:</strong> {receta.categoriaRecetas.join(', ')}</Typography>
              )}
              {receta.tipoPlato && receta.tipoPlato.length > 0 && (
                <Typography><strong>Tipo de Plato:</strong> {receta.tipoPlato.join(', ')}</Typography>
              )}
              {receta.momentoDelDia && (
                <Typography><strong>Momento del día:</strong> {receta.momentoDelDia}</Typography>
              )}
              {receta.externalUrl && (
                <Link href={receta.externalUrl} target="_blank" rel="noopener noreferrer">
                  Ver receta original
                </Link>
              )}
            </StyledCardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={12} md={8}>
          <StyledCard>
            <StyledCardContent>
              {receta.totalMacronutrients ? (
                <MacronutrientsChart totalMacronutrients={receta.totalMacronutrients} />
              ) : (
                <Typography>Información nutricional no disponible</Typography>
              )}
            </StyledCardContent>
          </StyledCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box sx={{ marginTop: 4 }}>
            <Tabs value={value} onChange={handleChange} centered>
              <Tab label="Ingredientes" />
              <Tab label="Pasos" />
            </Tabs>
            <Box sx={{ padding: 2 }}>
              {value === 0 && (
                <StyledTableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Ingrediente</TableCell>
                        <TableCell>Cantidad</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {receta.ingredients.map((ing, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {ingredientesDetallados.find(ingDetalle => ingDetalle._id === ing.ingredientId)?.name || 'Desconocido'}
                          </TableCell>
                          <TableCell>
                            {calculateAdjustedQuantity(ing.quantity).toLocaleString('es-ES', {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 2
                            })} {ing.unit}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              )}
              {value === 1 && (
                <StyledTableContainer component={Paper}>
                  <Table>
                    <TableBody>
                      {receta.steps && receta.steps.length > 0 ? (
                        receta.steps.map((step, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <Typography variant="subtitle1" fontWeight="bold">Paso {index + 1}</Typography>
                              <Typography variant="body1">{step}</Typography>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell>
                            <Typography variant="body1">Esta receta no tiene pasos definidos</Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ position: 'fixed', bottom: 16, right: 16, display: 'flex', flexDirection: 'column' }}>
        {userRole === 'admin' && (
          <Fab color="primary" aria-label="edit" onClick={handleEditRecipe} sx={{ mb: 1 }}>
            <Edit />
          </Fab>
        )}
        <Fab color="secondary" aria-label="add to menu" onClick={handleAddToMenu}>
          <Add />
        </Fab>
      </Box>

      <AddToMenuPopup
        open={isAddToMenuOpen}
        onClose={() => setIsAddToMenuOpen(false)}
        recipeId={id}
        recipeName={receta.name}
        authToken={authToken}
      />
    </Box>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default RecetaIndividual;