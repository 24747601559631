import React from 'react';
import { Dialog, DialogContent, DialogContentText } from '@mui/material';

const SuccessDialog = ({ open, message = 'Cambios guardados con éxito, redirigiendo...' }) => {
  return (
    <Dialog 
      open={open} 
      aria-labelledby="alert-dialog-title" 
      aria-describedby="alert-dialog-description"
      PaperProps={{
        className: "p-4"
      }}
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className="text-center">
          {message}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default SuccessDialog;