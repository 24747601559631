import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { TextField, FormControl, InputLabel, Select, MenuItem, Button, Grid, Box, Typography, Checkbox, FormControlLabel, Autocomplete } from '@mui/material';
import { useAuth } from '../context/AuthContext';
import SuccessDialog from './Common/SuccessDialog';
import RequiredFieldHelper from './Common/RequiredFieldHelper';

function fetchIngredient(id) {
    return fetch(`${process.env.REACT_APP_API_URL}/ingredients/${id}`).then(response => response.json());
}

function fetchCategorias() {
    return fetch(`${process.env.REACT_APP_API_URL}/categorias`).then(response => response.json());
}

function fetchIngredientCategories() {
    return fetch(`${process.env.REACT_APP_API_URL}/ingredient-categories`).then(response => response.json());
}

function fetchAllergens() {
    return fetch(`${process.env.REACT_APP_API_URL}/allergens`).then(response => response.json());
}

function updateIngredient(id, ingredientChanges) {
    const body = JSON.stringify(ingredientChanges);
    return fetch(`${process.env.REACT_APP_API_URL}/ingredients/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: body,
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('Error al actualizar el ingrediente. Por favor, verifica los datos y prueba de nuevo.');
            }
            return response.json();
        });
}

function EditarIngrediente() {
    const { userRole } = useAuth();
    const isAdmin = userRole === 'admin';
    const navigate = useNavigate();
    const { id } = useParams();
    const [ingredient, setIngredient] = useState(null);
    const [initialIngredient, setInitialIngredient] = useState(null);
    const [categorias, setCategorias] = useState([]);
    const [subcategorias, setSubcategorias] = useState([]);
    const [ingredientCategories, setIngredientCategories] = useState([]);
    const [allergens, setAllergens] = useState([]);
    const [revisado, setRevisado] = useState(false);
    const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

    useEffect(() => {
        if (!isAdmin) {
            navigate('/dashboard');
        }
    }, [isAdmin, navigate]);

    useEffect(() => {
        fetchCategorias().then(setCategorias);
        fetchIngredientCategories().then(setIngredientCategories);
        fetchAllergens().then(setAllergens);
    }, []);

    useEffect(() => {
        fetchIngredient(id).then(data => {
            setIngredient(data);
            setInitialIngredient(data);
            setRevisado(data.revisado);
        });
    }, [id]);

    useEffect(() => {
        if (ingredient && ingredient.categoria) {
            const categoria = categorias.find(categoria => categoria._id === ingredient.categoria);
            if (categoria) {
                setSubcategorias(categoria.subcategorias);
            }
        }
    }, [ingredient, categorias]);

    const handleCategoriaChange = (event) => {
        const categoria = categorias.find(categoria => categoria._id === event.target.value);
        if (categoria) {
            setIngredient({ ...ingredient, categoria: categoria._id });
            setSubcategorias(categoria.subcategorias);
        }
    };

    const handleSubcategoriaChange = (event) => {
        setIngredient({ ...ingredient, subcategoria: event.target.value });
    };

    const handleStandardUnitChange = (event) => {
        let newIngredient = { ...ingredient, standardUnit: event.target.value };
        if (event.target.value !== 'gramos') {
            newIngredient = { ...newIngredient, gramsPerUnit: '' };
        }
        setIngredient(newIngredient);
    };

    const handleRevisadoChange = (event) => {
        setRevisado(event.target.checked);
    };

    const handleIngredientCategoriesChange = (event, values) => {
        setIngredient({ ...ingredient, ingredientCategories: values.map(v => v._id) });
    };

    const handleAllergensChange = (event, values) => {
        setIngredient({ ...ingredient, allergens: values.map(v => v._id) });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const updatedIngredient = { ...ingredient, revisado };
        const ingredientChanges = Object.keys(updatedIngredient).reduce((changes, key) => {
            if (updatedIngredient[key] !== initialIngredient[key]) {
                changes[key] = updatedIngredient[key];
            }
            return changes;
        }, {});

        updateIngredient(id.trim(), ingredientChanges).then(updatedIngredient => {
            setIngredient(updatedIngredient);
            setInitialIngredient(updatedIngredient);
            setOpenSuccessDialog(true);
            setTimeout(() => {
                setOpenSuccessDialog(false);
                navigate(-1);
            }, 2000);
        }).catch(error => {
            alert(error.message);
        });
    };

    if (!ingredient) {
        return 'Cargando...';
    }

    return (
        <Box display="flex" justifyContent="center">
            <form onSubmit={handleSubmit}>
                <Typography variant="h1" align="center" gutterBottom>
                    Edita un ingrediente
                </Typography>
                <RequiredFieldHelper />

                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Typography variant="h6">Información básica</Typography>
                        <TextField
                            label="Nombre"
                            value={ingredient.name}
                            onChange={(event) => setIngredient({ ...ingredient, name: event.target.value })}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item container direction="row" spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Información nutricional</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Kilocalorías"
                                value={ingredient.kilocalories}
                                onChange={(event) => setIngredient({ ...ingredient, kilocalories: event.target.value })}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Carbohidratos"
                                value={ingredient.carbs}
                                onChange={(event) => setIngredient({ ...ingredient, carbs: event.target.value })}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Proteínas"
                                value={ingredient.protein}
                                onChange={(event) => setIngredient({ ...ingredient, protein: event.target.value })}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                label="Grasas"
                                value={ingredient.fat}
                                onChange={(event) => setIngredient({ ...ingredient, fat: event.target.value })}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction="row" spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Información de medidas</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel>Unidad estándar</InputLabel>
                                <Select value={ingredient.standardUnit} onChange={handleStandardUnitChange}>
                                    <MenuItem value="gramos">Gramos</MenuItem>
                                    <MenuItem value="uds">Unidades</MenuItem>
                                    <MenuItem value="cucharadas">Cucharadas</MenuItem>
                                    <MenuItem value="cucharaditas">Cucharaditas</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Gramos por unidad"
                                value={ingredient.gramsPerUnit}
                                onChange={(event) => setIngredient({ ...ingredient, gramsPerUnit: event.target.value })}
                                helperText={`¿Cuánto pesa aproximadamente una unidad de ${ingredient.name}?`}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction="row" spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Categorías de supermercado</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel>Categoría</InputLabel>
                                <Select value={ingredient.categoria} onChange={handleCategoriaChange}>
                                    {categorias.map(categoria => (
                                        <MenuItem key={categoria._id} value={categoria._id}>{categoria.nombre}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel>Subcategoría</InputLabel>
                                <Select value={ingredient.subcategoria} onChange={handleSubcategoriaChange}>
                                    {subcategorias.map(subcategoria => (
                                        <MenuItem key={subcategoria} value={subcategoria}>{subcategoria}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            multiple
                            options={ingredientCategories}
                            getOptionLabel={(option) => option.name}
                            value={ingredientCategories.filter(cat => ingredient.ingredientCategories.includes(cat._id))}
                            onChange={handleIngredientCategoriesChange}
                            renderInput={(params) => (
                                <TextField {...params} label="Categorías de Ingredientes" variant="outlined" fullWidth />
                            )}
                        />
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            multiple
                            options={allergens}
                            getOptionLabel={(option) => option.name}
                            value={allergens.filter(allergen => ingredient.allergens.includes(allergen._id))}
                            onChange={handleAllergensChange}
                            renderInput={(params) => (
                                <TextField {...params} label="Alérgenos" variant="outlined" fullWidth />
                            )}
                        />
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={revisado}
                                    onChange={handleRevisadoChange}
                                    name="revisado"
                                    color="primary"
                                />
                            }
                            label="Revisado"
                        />
                    </Grid>
                    <Grid item>
                        <Button type="submit" sx={{ bgcolor: 'primary.main', color: '#fff' }}>Guardar</Button>
                    </Grid>
                </Grid>
                <SuccessDialog
                    open={openSuccessDialog}
                    message="Ingrediente actualizado con éxito, redirigiendo..."
                />
            </form>
        </Box>
    );
}

export default EditarIngrediente;
