import React from 'react';
import { Typography, Box } from '@mui/material';

const RequiredFieldHelper = () => {
  return (
    <Box className="mb-4 mt-2">
      <Typography variant="caption" className="text-gray-600">
        * Los campos marcados con un asterisco son obligatorios
      </Typography>
    </Box>
  );
};

export default RequiredFieldHelper;