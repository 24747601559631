import React, { useState, useEffect, useCallback } from 'react';
import {
  Grid, TextField, Button, Autocomplete, Checkbox, ListItemText,
  CircularProgress, Select, MenuItem, FormControl, FormControlLabel,
  Paper, Typography, useMediaQuery, useTheme, InputLabel, Box, ListItem
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useAuth } from '../context/AuthContext';
import IngredientItem from './Common/IngredientItem'; // Asegúrate de importar el nuevo componente
import SuccessDialog from './Common/SuccessDialog';
import RequiredFieldHelper from './Common/RequiredFieldHelper';

function EditarReceta() {
  const { userRole, authToken } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [recipe, setRecipe] = useState({});
  const [ingredients, setIngredients] = useState([]);
  const [restrictions, setRestrictions] = useState([]);
  const [categoriaRecetas, setCategoriaRecetas] = useState([]);
  const [dishTypes, setDishTypes] = useState([]);
  const [mainImage, setMainImage] = useState('');
  const [loading, setLoading] = useState(true);
  const [totalMacronutrients, setTotalMacronutrients] = useState(null);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);

  const calculoNutrientesReceta = useCallback(async (ingredients, servings) => {
    console.log('Debug - Iniciando cálculo de nutrientes:', { ingredients, servings });
    const cantidadesDeIngredientes = {};

    for (const ingredient of ingredients) {
      if (!ingredient.ingredientId || ingredient.quantity === undefined) {
        console.log('Debug - Ingrediente inválido:', ingredient);
        continue;
      }

      if (!cantidadesDeIngredientes[ingredient.ingredientId]) {
        cantidadesDeIngredientes[ingredient.ingredientId] = {
          quantity: 0,
          gramsPerUnit: 1,
        };
      }

      cantidadesDeIngredientes[ingredient.ingredientId].quantity += ingredient.quantity / servings;
    }

    console.log('Debug - Cantidades de ingredientes:', cantidadesDeIngredientes);

    let totalMacronutrients = {
      kilocalories: 0,
      carbs: 0,
      protein: 0,
      fat: 0,
    };

    for (const ingredientId in cantidadesDeIngredientes) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/ingredients/${ingredientId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const ingredient = await response.json();
        console.log('Debug - Ingrediente obtenido:', ingredient);

        let consumedQuantity = cantidadesDeIngredientes[ingredientId].quantity;

        if (ingredient.standardUnit !== 'gramos') {
          consumedQuantity *= ingredient.gramsPerUnit;
        }

        consumedQuantity /= 100; // Convertir a 100g

        console.log('Debug - Cantidad consumida (en 100g):', consumedQuantity);

        totalMacronutrients.kilocalories += consumedQuantity * ingredient.kilocalories;
        totalMacronutrients.carbs += consumedQuantity * ingredient.carbs;
        totalMacronutrients.protein += consumedQuantity * ingredient.protein;
        totalMacronutrients.fat += consumedQuantity * ingredient.fat;

        console.log('Debug - Macronutrientes parciales:', totalMacronutrients);
      } catch (error) {
        console.error(`Error al obtener el ingrediente ${ingredientId}:`, error);
      }
    }

    Object.keys(totalMacronutrients).forEach(key => {
      totalMacronutrients[key] = parseFloat(totalMacronutrients[key].toFixed(2));
    });

    console.log('Debug - Macronutrientes totales finales:', totalMacronutrients);
    return totalMacronutrients;
  }, []);

  const actualizarMacronutrientes = useCallback(async (newIngredients, newServings) => {
    const newTotalMacronutrients = await calculoNutrientesReceta(newIngredients, newServings);
    console.log('Debug - Nuevos macronutrientes calculados:', newTotalMacronutrients);
    setTotalMacronutrients(newTotalMacronutrients);
  }, [calculoNutrientesReceta]);

  const handleIngredientChange = useCallback((index, field) => async (event, newValue) => {
    console.log('Debug - Cambio de ingrediente:', { index, field, event, newValue });
    let value;
    let unit;
    if (newValue) {
      value = newValue._id;
      unit = newValue.standardUnit !== 'grams' ? newValue.standardUnit : undefined;
    } else if (event.target) {
      value = event.target.value;
    }

    setRecipe(prevRecipe => {
      const newIngredients = [...prevRecipe.ingredients];
      newIngredients[index] = { ...newIngredients[index], [field]: value };
      if (unit) {
        newIngredients[index] = { ...newIngredients[index], unit };
      }
      console.log('Debug - Nuevos ingredientes:', newIngredients);
      actualizarMacronutrientes(newIngredients, prevRecipe.servings);
      return { ...prevRecipe, ingredients: newIngredients };
    });
  }, [actualizarMacronutrientes]);

  const handleQuantityChange = useCallback((e, index) => {
    const value = e.target.value.replace(',', '.');
    setRecipe(prevRecipe => {
      const updatedIngredients = [...prevRecipe.ingredients];
      updatedIngredients[index]['quantity'] = value;
      actualizarMacronutrientes(updatedIngredients, prevRecipe.servings);
      return { ...prevRecipe, ingredients: updatedIngredients };
    });
  }, [actualizarMacronutrientes]);

  const handleServingsChange = useCallback((e) => {
    const newServings = e.target.value;
    setRecipe(prevRecipe => {
      actualizarMacronutrientes(prevRecipe.ingredients, newServings);
      return { ...prevRecipe, servings: newServings };
    });
  }, [actualizarMacronutrientes]);

  useEffect(() => {
    if (!userRole || userRole !== 'admin') {
      navigate('/dashboard');
      return;
    }

    const fetchData = async () => {
      try {
        const [ingredientsData, categoriaRecetasData, recipeData, restriccionesData, tipoDeCocinaData] = await Promise.all([
          fetch(`${process.env.REACT_APP_API_URL}/ingredients`).then(res => res.json()),
          fetch(`${process.env.REACT_APP_API_URL}/categoriaRecetas`).then(res => res.json()),
          fetch(`${process.env.REACT_APP_API_URL}/recipes/${id}`).then(res => res.json()),
          fetch(`${process.env.REACT_APP_API_URL}/restricciones`).then(res => res.json()),
          fetch(`${process.env.REACT_APP_API_URL}/tipoDeCocina`).then(res => res.json()),
        ]);

        setIngredients(ingredientsData);
        setCategoriaRecetas(categoriaRecetasData);
        setRestrictions(restriccionesData);
        setDishTypes(tipoDeCocinaData);

        const recipeRestrictions = restriccionesData.filter(restriccion => recipeData.restricciones.includes(restriccion._id));
        const recipeTipoDeCocina = tipoDeCocinaData.filter(tipoDeCocina => recipeData.tipoDeCocina.includes(tipoDeCocina._id));
        const recipeCategoriaRecetas = categoriaRecetasData.filter(categoriaReceta => recipeData.categoriaRecetas.includes(categoriaReceta._id));

        // Asegúrate de que momentoDelDia sea un array
        const momentoDelDia = recipeData.momentoDelDia || '';

        setRecipe({
          ...recipeData,
          restricciones: recipeRestrictions,
          tipoDeCocina: recipeTipoDeCocina,
          categoriaRecetas: recipeCategoriaRecetas,
          momentoDelDia: momentoDelDia
        });

        const initialMacronutrients = await calculoNutrientesReceta(recipeData.ingredients, recipeData.servings);
        setTotalMacronutrients(initialMacronutrients);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id, userRole, navigate, calculoNutrientesReceta]);

  const handleChange = (e) => {
    setRecipe({ ...recipe, [e.target.name]: e.target.value });
  };

  const handleRevisadoChange = (event) => {
    setRecipe({ ...recipe, revisado: event.target.checked });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!recipe || !recipe.tipoDeCocina) {
      console.log('Recipe or recipe.tipoDeCocina is null or undefined');
      return;
    }
    const updatedRecipe = {
      name: recipe.name,
      ingredients: recipe.ingredients.map((ingredient) => ({
        ingredientId: ingredient.ingredientId,
        quantity: ingredient.quantity,
        unit: ingredient.unit,
      })),
      steps: recipe.steps,
      externalUrl: recipe.externalUrl,
      servings: recipe.servings,
      difficulty: recipe.difficulty,
      prepTime: recipe.prepTime,
      allergyInfo: Array.isArray(recipe.allergyInfo) ? recipe.allergyInfo : [recipe.allergyInfo],
      tipoDeCocina: Array.isArray(recipe.tipoDeCocina) ? recipe.tipoDeCocina.map((tipo) => tipo._id) : [recipe.tipoDeCocina._id],
      restricciones: Array.isArray(recipe.restricciones) ? recipe.restricciones.map((restriccion) => restriccion._id) : [recipe.restricciones._id],
      mainImage: recipe.mainImage,
      categoriaRecetas: Array.isArray(recipe.categoriaRecetas) ? recipe.categoriaRecetas.map((categoria) => categoria._id) : [recipe.categoriaRecetas._id],
      revisado: recipe.revisado,
      tipoPlato: Array.isArray(recipe.tipoPlato) ? recipe.tipoPlato : [recipe.tipoPlato],
      momentoDelDia: Array.isArray(recipe.momentoDelDia) ? recipe.momentoDelDia[0] : recipe.momentoDelDia, // Tomamos solo el primer valor
    };

    console.log("Actualizando con datos ", JSON.stringify(updatedRecipe));

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/recipes/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedRecipe),
      });

      if (response.ok) {
        setOpenSuccessDialog(true);
        setTimeout(() => {
          setOpenSuccessDialog(false);
          navigate(-1);
        }, 2000);
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.message}`);
      }
    } catch (error) {
      console.error('Error updating recipe:', error);
      alert('Error al actualizar la receta');
    }
  };

  const handleDeleteIngredient = (index) => {
    setRecipe(prevRecipe => {
      const updatedIngredients = [...prevRecipe.ingredients];
      updatedIngredients.splice(index, 1);
      actualizarMacronutrientes(updatedIngredients, prevRecipe.servings);
      return { ...prevRecipe, ingredients: updatedIngredients };
    });
  };

  const handleAddIngredient = () => {
    setRecipe(prevRecipe => ({
      ...prevRecipe,
      ingredients: [...prevRecipe.ingredients, { ingredientId: '', quantity: '', unit: '' }]
    }));
  };

  const handleStepChange = (e, index) => {
    const updatedSteps = [...recipe.steps];
    updatedSteps[index] = e.target.value;
    setRecipe({ ...recipe, steps: updatedSteps });
  };

  const handleDeleteStep = (index) => {
    const updatedSteps = [...recipe.steps];
    updatedSteps.splice(index, 1);
    setRecipe({ ...recipe, steps: updatedSteps });
  };

  const handleAddStep = () => {
    setRecipe({ ...recipe, steps: [...recipe.steps, ''] });
  };

  const handleNestedChange = (e) => {
    setRecipe({
      ...recipe,
      prepTime: {
        ...recipe.prepTime,
        [e.target.name]: parseInt(e.target.value, 10)
      }
    });
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/upload`, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${authToken}`
        },
      });

      if (!response.ok) {
        throw new Error('Error al subir la imagen');
      }

      const data = await response.json();
      setMainImage(data.path);
      setRecipe({
        ...recipe,
        mainImage: data.path,
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Paper elevation={3} style={{ padding: '20px', margin: '20px auto', maxWidth: isMobile ? '90%' : '60%' }}>
      <Typography variant="h4" align="center" gutterBottom>{recipe.name}</Typography>
      <RequiredFieldHelper />

      {totalMacronutrients && (
        <Box display="flex" justifyContent="center" mb={3}>
          <Grid container spacing={3} sx={{
            maxWidth: {
              xs: '85%',
              sm: '40%',
            }
          }}>
            <Grid item xs={3}>
              <Typography variant="body2">{totalMacronutrients.kilocalories}</Typography>
              <Typography variant="caption">Kilocalorías</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">{totalMacronutrients.protein}</Typography>
              <Typography variant="caption">Proteínas</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">{totalMacronutrients.carbs}</Typography>
              <Typography variant="caption">Carbohidratos</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">{totalMacronutrients.fat}</Typography>
              <Typography variant="caption">Grasas</Typography>
            </Grid>
          </Grid>
        </Box>
      )}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Nombre"
              name="name"
              value={recipe.name}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Ingredientes</Typography>
          </Grid>
          <Grid item xs={12}>
            {recipe.ingredients && recipe.ingredients.map((ingredient, index) => {
              const ingredientData = ingredients.find(i => i._id === ingredient.ingredientId);
              return (
                <Grid container spacing={2} key={index} alignItems="center">
                  <Grid item xs={10} sm={5}>
                    <Autocomplete
                      options={ingredients}
                      getOptionLabel={(option) => option.name}
                      value={ingredientData}
                      onChange={(event, newValue) => {
                        handleIngredientChange(index, 'ingredientId')(event, newValue);
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <IngredientItem ingredient={option} isAdmin={userRole === 'admin'} />
                        </li>
                      )}
                      renderInput={(params) => <TextField {...params} label="Ingrediente" required />}
                    />
                  </Grid>

                  <Grid item xs={8} sm={4}>
                    <TextField
                      label={`Cantidad (en ${ingredient.unit || 'gramos'})`}
                      name={`quantity-${index}`}
                      value={ingredient.quantity != null ? ingredient.quantity.toString().replace('.', ',') : ''}
                      onChange={(e) => handleQuantityChange(e, index)}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      type="text"
                      inputProps={{ step: "0.01", inputMode: "decimal" }}
                    />
                    {ingredientData && ingredientData.gramsPerUnit !== 1 && ingredientData.gramsPerUnit !== 0 && (
                      <Typography variant="body2" style={{ marginTop: 8 }}>
                        Peso medio por unidad {ingredientData.gramsPerUnit} gramos
                      </Typography>
                    )}
                  </Grid>
                  {ingredientData && (
                    <Grid item xs={2} sm={1}>
                      <Box display="flex" alignItems="center">
                        <IngredientItem
                          ingredient={ingredientData}
                          isAdmin={userRole === 'admin'}
                          showName={false}
                        />
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={2} sm={2}>
                    <IconButton onClick={() => handleDeleteIngredient(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              )
            })}
            <Grid item xs={12}>
              <Button variant="outlined" onClick={handleAddIngredient} startIcon={<AddIcon />}>
                Añadir Ingrediente
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Pasos</Typography>
          </Grid>
          <Grid item xs={12}>
            {recipe.steps && recipe.steps.map((step, index) => (
              <Grid container spacing={2} key={index}>
                <Grid item xs={10}>
                  <TextField
                    label={`Paso ${index + 1}`}
                    name={`step-${index}`}
                    value={step}
                    onChange={(e) => handleStepChange(e, index)}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    multiline
                  />
                </Grid>
                <Grid item xs={2}>
                  <IconButton onClick={() => handleDeleteStep(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button variant="outlined" onClick={handleAddStep} startIcon={<AddIcon />}>
                Añadir Paso
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Enlace a la receta"
              name="externalUrl"
              value={recipe.externalUrl}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Raciones"
              name="servings"
              value={recipe.servings}
              onChange={handleServingsChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="difficulty-label">Dificultad</InputLabel>
              <Select
                labelId="difficulty-label"
                name="difficulty"
                value={recipe.difficulty}
                onChange={handleChange}
                fullWidth
              >
                <MenuItem value="Baja">Baja</MenuItem>
                <MenuItem value="Media">Media</MenuItem>
                <MenuItem value="Alta">Alta</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Tiempo de Preparación</Typography>
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              label="Horas"
              name="hours"
              value={recipe.prepTime ? recipe.prepTime.hours : ''}
              onChange={handleNestedChange}
              type="number"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextField
              label="Minutos"
              name="minutes"
              value={recipe.prepTime ? recipe.prepTime.minutes : ''}
              onChange={handleNestedChange}
              type="number"
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Categorías</Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id="restricciones"
              options={restrictions}
              getOptionLabel={(option) => option.name}
              value={recipe.restricciones}
              onChange={(event, newValue) => {
                setRecipe({ ...recipe, restricciones: newValue });
              }}
              filterSelectedOptions
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} />
                  <ListItemText primary={option.name} />
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Restricciones" placeholder="Restricciones" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id="tipoDeCocina"
              options={dishTypes}
              getOptionLabel={(option) => option.name}
              value={recipe.tipoDeCocina}
              onChange={(event, newValue) => {
                setRecipe({ ...recipe, tipoDeCocina: newValue });
              }}
              filterSelectedOptions
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} />
                  <ListItemText primary={option.name} />
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Tipo de Cocina" placeholder="Tipo de Cocina" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id="categoriaRecetas"
              options={categoriaRecetas}
              getOptionLabel={(option) => option.nombre}
              value={recipe.categoriaRecetas}
              onChange={(event, newValue) => {
                setRecipe({ ...recipe, categoriaRecetas: newValue });
              }}
              filterSelectedOptions
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} />
                  <ListItemText primary={option.nombre} />
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Categoría Recetas" placeholder="Categoría Recetas" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              id="tipoPlato"
              options={['Desayuno', 'Snack', 'Entrante', 'Principal', 'Postre', 'Guarnición']}
              getOptionLabel={(option) => option}
              value={recipe.tipoPlato}
              onChange={(event, newValue) => {
                setRecipe({ ...recipe, tipoPlato: newValue });
              }}
              filterSelectedOptions
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} />
                  <ListItemText primary={option} />
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Tipo de Plato" placeholder="Tipo de Plato" />
              )}
            />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: '8px', marginTop: '16px' }}>
            <Autocomplete
              id="momentoDelDia"
              options={['Desayuno', 'Comida', 'Cena', 'Snack']}
              getOptionLabel={(option) => option || ''}
              value={recipe.momentoDelDia || ''}
              onChange={(event, newValue) => {
                setRecipe(prev => ({
                  ...prev,
                  momentoDelDia: newValue
                }));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Momento del día"
                  placeholder="Selecciona el momento del día"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={recipe.revisado}
                  onChange={handleRevisadoChange}
                  name="revisado"
                  color="primary"
                />
              }
              label="Revisado"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <input
                accept="image/*"
                id="mainImage"
                type="file"
                onChange={(e) => handleImageUpload(e)}
                style={{ display: 'none' }}
              />
              <label htmlFor="mainImage">
                <Button variant="contained" color="primary" component="span">
                  Subir Imagen Principal
                </Button>
              </label>
              {recipe.mainImage && (
                <Grid container justifyContent="center" style={{ marginTop: '10px' }}>
                  <Grid item>
                    <img
                      src={recipe.mainImage}
                      alt="Imagen Principal"
                      style={{ width: '100px', height: '100px' }}
                    />
                  </Grid>
                </Grid>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Actualizar Receta
            </Button>
          </Grid>
        </Grid>
      </form>
      <SuccessDialog 
            open={openSuccessDialog} 
            message="Receta actualizada con éxito, redirigiendo..."
        />
    </Paper>
  );
}
export default EditarReceta;