import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

function RecipeCard({ recipe, types, restrictions, recipeCategories }) {
  const navigate = useNavigate();
  const { userRole } = useAuth();
  const { name, difficulty, prepTime, servings, mainImage, _id, categoriaRecetas, 
    restricciones, tipoPlato, tipoDeCocina, totalMacronutrients, momentoDelDia } = recipe;
  const theme = useTheme();

  const difficultyColors = {
    "Baja": theme.palette.success.main,
    "Media": theme.palette.warning.main,
    "Alta": theme.palette.error.main
  };

  const cocinaNombres = (tipoDeCocina || []).map(id => types.find(type => type._id === id)?.name || id);
  const restriccionesNombres = (restricciones || []).map(id => restrictions.find(restriction => restriction._id === id)?.name || id);
  const categoriaNombres = (categoriaRecetas || []).map(id => recipeCategories.find(category => category._id === id)?.nombre || id);

  const handleViewRecipe = () => navigate(`/recetas/${_id}`);
  const handleEditRecipe = () => navigate(`/editar-receta/${_id}`);

  // Combine all tags, ensuring tipoPlato is an array before spreading
  const allTags = [
    ...cocinaNombres,
    ...restriccionesNombres,
    ...categoriaNombres,
    ...(Array.isArray(tipoPlato) ? tipoPlato : [tipoPlato].filter(Boolean)),
    ...(Array.isArray(momentoDelDia) ? momentoDelDia : [momentoDelDia].filter(Boolean))
];

  return (
    <Card sx={{
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
      '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: '0 6px 12px rgba(0,0,0,0.15)',
      }
    }}>
      <CardMedia
        component="img"
        height="200"
        image={mainImage}
        alt={`Imagen de ${name}`}
        sx={{ objectFit: 'cover' }}
      />
      <CardContent sx={{ flexGrow: 1, padding: 2 }}>
        <Typography gutterBottom variant="h6" component="div" sx={{ fontWeight: 'bold', mb: 2 }}>
          {name}
        </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          {difficulty && (
            <Box display="flex" alignItems="center">
              <Typography variant="body2" sx={{ fontWeight: 'bold', mr: 0.5 }}>
                Dificultad:
              </Typography>
              <Chip
                label={difficulty}
                size="small"
                sx={{
                  bgcolor: difficultyColors[difficulty],
                  color: 'white',
                  fontWeight: 'bold',
                }}
              />
            </Box>
          )}
          {prepTime && (
            <Box display="flex" alignItems="center">
              <AccessTimeIcon fontSize="small" sx={{ mr: 0.5, color: theme.palette.text.secondary }} />
              <Typography variant="body2" color="text.secondary">
                {`${prepTime.hours ? `${prepTime.hours}h` : ''} ${prepTime.minutes ? `${prepTime.minutes}min` : ''}`.trim()}
              </Typography>
            </Box>
          )}
        </Box>
        <Box sx={{ mb: 1 }}>
          {allTags.slice(0, 4).map((tag, index) => (
            <Chip
              key={`tag-${index}`}
              label={tag}
              size="small"
              sx={{
                mr: 0.5,
                mb: 0.5,
                bgcolor: theme.palette.primary.light,
                color: theme.palette.primary.contrastText
              }}
            />
          ))}
        </Box>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography variant="body2" color="text.secondary">
            Kcal: {totalMacronutrients?.kilocalories || 'N/A'}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            C: {totalMacronutrients?.carbs || 'N/A'}g
          </Typography>
          <Typography variant="body2" color="text.secondary">
            P: {totalMacronutrients?.protein || 'N/A'}g
          </Typography>
          <Typography variant="body2" color="text.secondary">
            G: {totalMacronutrients?.fat || 'N/A'}g
          </Typography>
        </Box>
      </CardContent>
      <CardActions sx={{ flexDirection: 'column', padding: 2 }}>
        <Button
          size="small"
          onClick={handleViewRecipe}
          variant="contained"
          sx={{
            bgcolor: theme.palette.primary.main,
            '&:hover': {
              bgcolor: theme.palette.primary.dark,
            },
            mb: 1 // Add margin bottom to separate the buttons
          }}
        >
          Ver Receta
        </Button>
        {userRole === 'admin' && (
          <Button
            size="small"
            onClick={handleEditRecipe}
            variant="outlined"
            sx={{
              color: theme.palette.secondary.main,
              borderColor: theme.palette.secondary.main,
              '&:hover': {
                bgcolor: theme.palette.secondary.light,
              }
            }}
          >
            Editar
          </Button>
        )}
      </CardActions>
    </Card>
  );
}

export default RecipeCard;
